<template>
  <div>
    <v-select
      dense
      :items="contractStatusValues"
      v-model="selectedOption"
      return-object
      single-line
      :disabled="role !== 'admin' || role !== 'coordinator' ? false : true"
    ></v-select>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: String,
      default: "disponible"
    }
  },
  data() {
    return {
      //"disponible", "reservado", "vendido"
      role: localStorage.getItem("user_role"),
      contractStatusValues: [
        {
          text: "DISPONIBLE",
          value: "disponible"
        },
        {
          text: "RESERVADO",
          value: "reservado"
        },
        {
          text: "VENDIDO",
          value: "vendido"
        },
        {
          text: "INDEFINIDO",
          value: "indefinido"
        }
      ],
      selectedOption: ""
    };
  },
  watch: {
    selectedOption(newValue) {
      if (newValue !== this.selected) {
        this.$emit(
          "getOption",
          this.contractStatusValues.find(item => item.value == newValue.value)
        );
        return this.contractStatusValues.find(
          item => item.value == newValue.value
        );
      }
    }
  },
  created() {
    this.consultStatuLead();
  },
  methods: {
    consultStatuLead() {
      this.selectedOption = this.selected;
      this.contractStatusValues.find(item => item.text == this.selected);
    }
  }
};
</script>
